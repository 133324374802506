import React from 'react';
import PropTypes from 'prop-types';
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images } from "./images";
import Fade from 'react-reveal/Fade';
import FeatureSectionWrapper from './featureSection.style';
import Heading from '../../../common/components/Heading';


const FeatureSection = ({
  sectionHeader,
}) => {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  return (
    <Fade up delay={50}>
      <FeatureSectionWrapper id="highlights" >
        <Heading as="h2" content="Lavori recenti" style={{
          textAlign: 'center',
          marginBottom: '50px',
          fontSize: '60px',
          fontWeight: 'bold',
          color: '#fff',
          lineHeight: '1.2',
          whiteSpace: 'pre-line',
          letterSpacing: 'normal',
          textTransform: 'none',
          fontStyle: 'normal',
        }} />
      <div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
      </FeatureSectionWrapper>
    </Fade>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    pr: ['0%', "5%", "16%", "5%"],
    pl: ['0%', "5%", "16%", "5%"],
    mb: ['40px', '40px', '40px', '50px'],
    display: 'flex',
    width: '100%',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['28px', '60px'],
    fontWeight: '700',
    lineHeight: '130%',
    color: '#fff',
    mb: '15px',
    w: ['100%', '40%'],

  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['14px', '24px'],
    fontWeight: '500',
    lineHeight: '156%',
    color: '#E0DAE2',
    mb: '0',
    w: ['100%', '40%'],
  },
};

export default FeatureSection;
