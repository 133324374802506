import styled from 'styled-components';

const BannerWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
  text-align: center;
  min-height: 80vh;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    background: none;
    margin-bottom: 50px;
  }
  @media only screen and (min-width: 992px) {
    min-height: 100vh;
  }
  > div.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100%;
    width: 100%;
  }
`;


export const BannerImage = styled.div`
  display: flex;
  justify-content: center;
  margin-right: -20px;
  @media (max-width: 992px) {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const ButtonGroup = styled.div`
  
  display: flex;
  justify-content: center;
  position: relative;
  top: 30px;
  .reusecore__button {
    text-transform: inherit;
    border-radius: 12px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
    font-weight: 600;
    height: 70px;
    width: 175px;

    &.primary {
      background: linear-gradient(84.85deg, #572FEC 2.51%, #9221E5 71.28%, #702BC4 111.85%);
      box-shadow: 0px 2px 33px rgba(146, 33, 229, 0.32);
      &:hover {
        box-shadow: 0px 2px 33px rgba(146, 33, 229, 0.32);
      }
    }
    

    &.text {
      margin-left: 15px;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.302);
    }
  }
`;

export const ImageBackground = styled.div`
  width: 700px;
  height: 700px;
  display: flex;
  background: rgba(146, 33, 229, 0.89);
  transform: translate3d(0,0,0);
  transform: translateZ(0);
  filter: blur(1000px);
`;


export default BannerWrapper;
