import React, {useEffect, useRef} from 'react';
import Container from '../../../common/components/UI/Container';
import BannerWrapper, {
} from './banner.style';
import BannerAnimations from './animations';
// import supra from '../../../common/assets/spwrap/yaris.mp4';
import Logos from './logos';
import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3131318a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  width: 60%;
  height: 500px;
  margin-bottom: 50px;
  @media (max-width: 992px) {
    width: 70%;
    margin-top: 50px;
    height: 450px;
  }
`

export const VideoBg = styled.video`
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 100%;
  max-height: 100%;
  pointer-events: none;
  object-fit: cover;
  z-index: 1;
`

const Banner = () => {
  const playerRef = useRef();

  useEffect(() => {
    let playVideo = (event) => {
      if (playerRef.current) {
        playerRef.current.play()
      }
    }
    playVideo()
  }, [])
  return (
      <div>
        <div id="hero">
        <VideoBg ref={playerRef} controls={false} muted="muted" playsInline="playsinline" loop="loop" id="gemsBg" autobuffer="true" preload="true" autoPlay="autoplay"
               style={{opacity: `0.2`}} >
          <source src={'https://spwrapcontent.s3.eu-central-1.amazonaws.com/Yaris+Bg.mp4'} type="video/mp4" />
        </VideoBg>
          <BannerWrapper >
              <Container noGutter mobileGutter>
                <LogoWrapper>
                  <BannerAnimations />
                  <Logos />
                </LogoWrapper>
              </Container>
          </BannerWrapper>
        </div>
      </div>

  );
};

export default Banner;
