import React from "react";
import useWindowDimensions from "../../../common/hooks/useWindowDimensions";

const BannerAnimations = () => {
	const {width} = useWindowDimensions();
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: width >= 768 ? "translate(-140%, -39.5%) scale(1.2)" : "translate(-142%, -39.8%) scale(1.2)",
        }}
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="250%"
          viewBox="0 0 768 528"
          enable-background="new 0 0 768 528"
        >
          <path
            id='path1'
            opacity="1.000000"
            fill="none"
            stroke="none"
            d="
M484.362183,84.577515 
	C500.252289,90.078789 497.969299,87.313667 498.033569,103.717590 
	C498.133453,129.202362 498.026367,154.687881 498.027710,180.173080 
	C498.030151,225.915710 478.619354,261.561279 441.867432,288.185852 
	C429.119720,297.420807 415.339661,304.731812 400.631592,310.337494 
	C397.853821,311.396210 395.147705,311.788147 392.373077,310.731842 
	C362.992584,299.546356 337.494171,282.918304 318.317780,257.546509 
	C302.672943,236.847214 294.307556,213.414902 293.898743,187.535324 
	C293.409424,156.560699 293.779205,125.573006 293.656158,94.591454 
	C293.641327,90.860054 295.000153,88.769409 298.513153,87.520988 
	C329.566772,76.485329 360.594757,65.377205 391.607086,54.225895 
	C394.630920,53.138607 397.368774,53.420719 400.323883,54.484306 
	C428.197021,64.516144 456.100403,74.464027 484.362183,84.577515 
M426.616699,172.085587 
	C416.818237,182.978149 407.030457,193.880402 397.213501,204.756287 
	C394.826172,207.401154 394.121704,210.501373 394.132904,213.972443 
	C394.174896,226.966080 394.096436,239.960068 394.113251,252.953873 
	C394.114868,254.203613 393.701416,255.631149 394.902374,256.661194 
	C405.532806,250.165466 407.789368,246.367950 407.881195,234.877823 
	C407.906494,231.712769 408.105835,228.538345 407.928070,225.384979 
	C407.666504,220.745117 409.349609,217.165451 412.481232,213.728546 
	C423.132629,202.038879 433.790833,190.335953 443.943420,178.216736 
	C453.900970,166.330353 453.005707,149.270355 442.500305,137.431793 
	C432.953430,126.673347 415.140503,124.117622 402.701355,131.772751 
	C400.285583,133.259445 397.139496,134.859558 396.751099,137.557632 
	C395.675873,145.026245 395.800232,152.599838 396.695923,160.410339 
	C400.222778,159.208923 401.549622,156.516144 403.536804,154.599564 
	C405.570099,152.638565 407.301544,150.354401 409.398499,148.471634 
	C414.908081,143.524872 422.384644,143.425613 428.101898,148.050507 
	C433.579346,152.481445 434.953033,159.644089 431.332916,166.023972 
	C430.197723,168.024551 428.520935,169.717850 426.616699,172.085587 
M358.897217,168.737671 
	C359.566925,165.840118 361.560608,163.796570 363.449707,161.687057 
	C370.558258,153.749023 377.098907,145.187378 384.934296,138.046600 
	C393.275879,130.444534 396.303131,121.755569 394.778503,110.985909 
	C394.429962,108.523819 395.625305,105.742348 393.488678,103.490219 
	C392.799286,103.829094 392.249817,103.937317 391.949158,104.270218 
	C377.221008,120.577232 361.853973,136.364807 347.978821,153.369522 
	C332.046844,172.894913 343.315399,200.559341 368.087067,204.535904 
	C376.894958,205.949799 385.033447,203.835220 391.978760,198.155685 
	C397.186310,193.897186 393.782013,187.826904 394.738190,182.655930 
	C394.827545,182.172791 394.798615,181.652893 394.740692,181.159912 
	C394.427063,178.490738 395.529938,175.561508 393.375610,172.893036 
	C389.347198,175.498337 386.705048,179.176025 383.590790,182.330017 
	C378.164001,187.826050 372.046082,189.021194 365.499268,185.662735 
	C359.797119,182.737579 357.309021,176.809433 358.897217,168.737671 
z"
          />
          <path
            id="path2"
            fill="none"
            opacity="1.000000"
            stroke="url(#linearGradient1)"
            stroke-width="2"
            stroke-dasharray="600"
            stroke-dashoffset="500"
            d="
M484.362183,84.577515 
	C500.252289,90.078789 497.969299,87.313667 498.033569,103.717590 
	C498.133453,129.202362 498.026367,154.687881 498.027710,180.173080 
	C498.030151,225.915710 478.619354,261.561279 441.867432,288.185852 
	C429.119720,297.420807 415.339661,304.731812 400.631592,310.337494 
	C397.853821,311.396210 395.147705,311.788147 392.373077,310.731842 
	C362.992584,299.546356 337.494171,282.918304 318.317780,257.546509 
	C302.672943,236.847214 294.307556,213.414902 293.898743,187.535324 
	C293.409424,156.560699 293.779205,125.573006 293.656158,94.591454 
	C293.641327,90.860054 295.000153,88.769409 298.513153,87.520988 
	C329.566772,76.485329 360.594757,65.377205 391.607086,54.225895 
	C394.630920,53.138607 397.368774,53.420719 400.323883,54.484306 
	C428.197021,64.516144 456.100403,74.464027 484.362183,84.577515 
M426.616699,172.085587 
	C416.818237,182.978149 407.030457,193.880402 397.213501,204.756287 
	C394.826172,207.401154 394.121704,210.501373 394.132904,213.972443 
	C394.174896,226.966080 394.096436,239.960068 394.113251,252.953873 
	C394.114868,254.203613 393.701416,255.631149 394.902374,256.661194 
	C405.532806,250.165466 407.789368,246.367950 407.881195,234.877823 
	C407.906494,231.712769 408.105835,228.538345 407.928070,225.384979 
	C407.666504,220.745117 409.349609,217.165451 412.481232,213.728546 
	C423.132629,202.038879 433.790833,190.335953 443.943420,178.216736 
	C453.900970,166.330353 453.005707,149.270355 442.500305,137.431793 
	C432.953430,126.673347 415.140503,124.117622 402.701355,131.772751 
	C400.285583,133.259445 397.139496,134.859558 396.751099,137.557632 
	C395.675873,145.026245 395.800232,152.599838 396.695923,160.410339 
	C400.222778,159.208923 401.549622,156.516144 403.536804,154.599564 
	C405.570099,152.638565 407.301544,150.354401 409.398499,148.471634 
	C414.908081,143.524872 422.384644,143.425613 428.101898,148.050507 
	C433.579346,152.481445 434.953033,159.644089 431.332916,166.023972 
	C430.197723,168.024551 428.520935,169.717850 426.616699,172.085587 
M358.897217,168.737671 
	C359.566925,165.840118 361.560608,163.796570 363.449707,161.687057 
	C370.558258,153.749023 377.098907,145.187378 384.934296,138.046600 
	C393.275879,130.444534 396.303131,121.755569 394.778503,110.985909 
	C394.429962,108.523819 395.625305,105.742348 393.488678,103.490219 
	C392.799286,103.829094 392.249817,103.937317 391.949158,104.270218 
	C377.221008,120.577232 361.853973,136.364807 347.978821,153.369522 
	C332.046844,172.894913 343.315399,200.559341 368.087067,204.535904 
	C376.894958,205.949799 385.033447,203.835220 391.978760,198.155685 
	C397.186310,193.897186 393.782013,187.826904 394.738190,182.655930 
	C394.827545,182.172791 394.798615,181.652893 394.740692,181.159912 
	C394.427063,178.490738 395.529938,175.561508 393.375610,172.893036 
	C389.347198,175.498337 386.705048,179.176025 383.590790,182.330017 
	C378.164001,187.826050 372.046082,189.021194 365.499268,185.662735 
	C359.797119,182.737579 357.309021,176.809433 358.897217,168.737671 
z"
          >
          <animate
              attributeName="stroke-dashoffset"
              values="1200;0"
              dur="4s"
              repeatCount="indefinite"
            />
          </path>
          <defs>
            <linearGradient id="linearGradient1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="10%" stopColor="#ffffff00" stop-opacity="0" />
              <stop offset="100%" stopColor="#0651d1" stop-opacity="1" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default BannerAnimations;
