import React from 'react';
import Box from '../../../common/components/Box';
import Heading from '../../../common/components/Heading';
import Container from '../../../common/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';
import Fade from 'react-reveal/Fade';
import smart from '../../../common/assets/spwrap/ChiSiamo.png';
import styled from 'styled-components';
import useWindowDimensions from '../../../common/hooks/useWindowDimensions';
import { graphql, useStaticQuery } from 'gatsby';

const ImageContainer = styled.div`
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    background-image: url(${smart});
    background-size: cover;
    border-radius: 17px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0.4) 25%, rgba(23, 23, 23, 0.3) 75%, rgba(23, 23, 23, 1) 100%);
      border-radius: inherit;
      pointer-events: none;
    }
`

const SmartSection = () => {
    const data = useStaticQuery(graphql`
      query {
        cryptoModernJson {
            Smart {
              text
              subtext
              description
          }
        }
      }
    `);
    const { Smart } = data.cryptoModernJson;
    const {width} = useWindowDimensions()

  return (

      <FeatureSectionWrapper id="investment" >
        <Container mobileGutter width={'75%'} style={{zIndex: 10}}>
          <Fade up delay={50}>
            <ImageContainer>
            <Fade up delay={100} style={{paddingBottom: '10px'}}>
              <Box className="sectionHeader" style={{position: 'relative', zIndex: 10}}>
                <div style={{
                  marginTop: width >= 992 ? '600px' : '350px', 
                  display: 'flex', flexDirection: 'column', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  background: 'rgba(8, 8, 11, 0.52)', 
                  width: width >= 768 ? '70%' : '95%',
                  borderRadius: '17px',
                  padding: '10px 0px'}}>
                <Heading as="h1" style={{
                  fontSize: width >= 992 ? '60px' : '30px',
                  lineHeight: width >= 992 ? '72px' : '36px',
                  marginBottom: '0px',
                  fontWeight: 500,
                  textAlign: 'center',
                }}
                content={Smart[0].text}  />
                <Heading as="h1" style={{fontSize: width >= 992 ? '60px' : '30px', fontWeight: 500, color: 'rgb(133, 157, 249)', lineHeight: width >= 992 ? '72px' : '36px',whiteSpace: "pre-line"}}
                content={Smart[0].subtext}  />
                <Heading as="h2" style={{lineHeight: width >= 992 ? '150%' : '180%', fontSize: width >= 992 ? '30px' : '18px', fontWeight: 500, marginTop: width >= 992 ? '30px' : '0px', width: width >= 992 ? '50%' : '100%'}}
                content={Smart[0].description}  />
                </div>
              </Box>
              </Fade>
            </ImageContainer>
          </Fade>
        </Container>
      </FeatureSectionWrapper>
  );
};

export default SmartSection;
