
export const images = [
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/500_gialla.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/500_gialla.jpg",
    width: 310,
    height: 190,
    tags: [
      { value: "Wrap", title: "Wrap" },
    ],
    caption: "500",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/bmw_m4.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/bmw_m4.jpg",
    width: 280,
    height: 210,
    tags: [
      { value: "Wrap", title: "Wrap" },
      { value: "Detailing", title: "Detailing"}
    ],
    caption: "M4",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/classe_c.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/classe_c.jpg",
    width: 220,
    height: 180,
    tags: [
      { value: "Wrap", title: "Wrap" },
    ],
    caption: "Classe C",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/cbr_600.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/cbr_600.jpg",
    width: 280,
    height: 174,
    tags: [
      { value: "PPF", title: "PPF" },
    ],
    caption: "CBR 600",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/glc.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/glc.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Wrap", title: "Wrap" },
    ],
    caption: "glc",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/ferrari_360.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/ferrari_360.jpg",
    width: 270,
    height: 154,
    tags: [
      { value: "Wrap", title: "Wrap" },
      { value: "Detailing", title: "Detailing"}
    ],
    caption: "Ferrari 360",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/golf_verde.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/golf_verde.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Wrap", title: "Wrap" },
    ],
    caption: "Golf",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A5709.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A5709.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Wrap", title: "Wrap" },
    ],
    caption: "Vw 8r",
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A6740.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A6740.jpg",
    width: 320,
    height: 150,
    caption: "Toyota Supra",
    tags: [
        { value: "PPF", title: "PPF" }
    ],
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A5177.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A5177.jpg",
    width: 320,
    height: 150,
    caption: "Audi A3",
    tags: [
        { value: "Wrap", title: "Wrap" }
    ],
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A5230.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/1Y6A5230.jpg",
    width: 320,
    height: 213,
    caption: "Audi A3",
    tags: [
        { value: "Wrap", title: "Wrap" }
    ],
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/GTR_PPF_3-24.jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/GTR_PPF_3-24.jpg",
    width: 320,
    height: 250,
    caption: "Nissan GT-R",
    tags: [
        { value: "Detailing", title: "Detailing" },
        { value: "PPF", title: "PPF" }
    ],
  },
  {
    src: "https://i.imgur.com/3HuIOhy.jpeg",
    original: "https://i.imgur.com/3HuIOhy.jpeg",
    width: 240,
    height: 320,
    tags: [{ value: "Wrap", title: "Wrap" }],
    caption: "Yaris GR",
  },
  {
    src: "https://i.imgur.com/TrnD9np.jpeg",
    original: "https://i.imgur.com/TrnD9np.jpeg",
    width: 320,
    height: 190,
    caption: "VW Eos",
    tags: [
        { value: "Detailing", title: "Detailing" },
        { value: "PPF", title: "PPF" }
    ],
  },
  {
    src: "https://i.imgur.com/lAjOrZA.jpeg",
    original: "https://i.imgur.com/lAjOrZA.jpeg",
    width: 320,
    height: 148,
    tags: [{ value: "Detailing", title: "Detailing" }],
    caption: "Bmw M2",
  },
  {
    src: "https://i.imgur.com/XlQa2XV.jpeg",
    original: "https://i.imgur.com/XlQa2XV.jpeg",
    width: 320,
    height: 213,
    caption: "Bmw M5",
    tags: [
        { value: "Detailing", title: "Detailing" },
        { value: "Wrap", title: "Wrap" }
    ],
  },
  {
    src: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/Audi+TT+-+Post+(1).jpg",
    original: "https://spwrapcontent.s3.eu-central-1.amazonaws.com/Audi+TT+-+Post+(1).jpg",
    width: 320,
    height: 213,
    caption: "Audi TT",
    tags: [
        { value: "Detailing", title: "Detailing" },
        { value: "Wrap", title: "Wrap" },
        { value: "Luci", title: "Luci" }
    ],
  }
];