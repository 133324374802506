import styled from 'styled-components';
import {themeGet} from "@styled-system/theme-get";

const FeatureSectionWrapper = styled.section`
  
  .iframe {
    div{
      background-color: transparent;

    }
  }
  
  padding: 10px 0;
  .container  {
    position: relative;
    border-radius: 12px;
    
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  
  .bookContainer {
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-top: 90px;
    @media (max-width: 992px) {
      margin-top: 80px;
    }
  }

  h1 {
    color: white;
    font-size: 60px;
    font-weight: 700;
    font-style: normal;
    color: ${themeGet('colors.menu', '#0D233E')};
    //line-height: 110%;
    @media only screen and (max-width: 1440px) {
      font-size: 45px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 40px;
      text-align: center;
    }
    @media only screen and (max-width: 350px) {
      font-size: 30px;
      text-align: center;
    }
  }

  h2 {
    color: white;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 18px;
      text-align: center;
    }
  }
  h3 {
    color: white;
    font-size: 18px;

    font-weight: 400;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 13px;
      text-align: center;
    }
  }
  .text-docs {
    display: flex;
    color: white;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    cursor: default;
    a {
      text-decoration-line: underline;
      cursor: pointer;
    }
    a:link {
      color: white;
    }

    a:visited {
      color: white;
    }

    a:hover {
      color: white;
    }

    a:active {
      color: white;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .image-defi {
    position: absolute;
    right: -134px;
    top: 0;
    bottom: 0;
    width: 400px;
    height: 400px;
    margin: auto;
    @media (max-width: 990px) {
      visibility: hidden;
    }
  }
  .sectionHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .feature__block {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 12px;
  
    &:hover {
      background-image: linear-gradient(
        to bottom,
        transparent 50%,
        rgba(255, 255, 255, 0.031)
      );
    }
    @media (max-width: 500px) {
      padding: 15px 0;
      &:hover {
        background-image: none;
      }
    }
  }
  .reusecore__button {
    border-radius: 12px;
    height: 50px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
    font-size: 18px;
    letter-spacing: -0.1px;
    //padding-left: 16px;
    //padding-right: 16px;
    font-weight: 400;
    svg {
     margin-right: 15px;
    }
    &:hover {
        background-color: #ffffff;
        color: #320940;
      svg {
        fill: #320940;
      }
    }
  }
`;

export default FeatureSectionWrapper;
