import React from "react";
import styled from "styled-components";

//centered position absolute image container
const CenteredImageContainer = styled.div`
    position: absolute;
    top: 60%;
    left: 50.5%;
    transform: translate(-50%, -50%) scale(1.5);
`;



const Logos = () => {
  return (
    <>
    <CenteredImageContainer style={{top: '50%', transform: 'translate(-53%, -22%) scale(3)'}}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="100%" viewBox="0 0 768 528" enable-background="new 0 0 768 528" >
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M484.362183,84.577515 
	C500.252289,90.078789 497.969299,87.313667 498.033569,103.717590 
	C498.133453,129.202362 498.026367,154.687881 498.027710,180.173080 
	C498.030151,225.915710 478.619354,261.561279 441.867432,288.185852 
	C429.119720,297.420807 415.339661,304.731812 400.631592,310.337494 
	C397.853821,311.396210 395.147705,311.788147 392.373077,310.731842 
	C362.992584,299.546356 337.494171,282.918304 318.317780,257.546509 
	C302.672943,236.847214 294.307556,213.414902 293.898743,187.535324 
	C293.409424,156.560699 293.779205,125.573006 293.656158,94.591454 
	C293.641327,90.860054 295.000153,88.769409 298.513153,87.520988 
	C329.566772,76.485329 360.594757,65.377205 391.607086,54.225895 
	C394.630920,53.138607 397.368774,53.420719 400.323883,54.484306 
	C428.197021,64.516144 456.100403,74.464027 484.362183,84.577515 
M426.616699,172.085587 
	C416.818237,182.978149 407.030457,193.880402 397.213501,204.756287 
	C394.826172,207.401154 394.121704,210.501373 394.132904,213.972443 
	C394.174896,226.966080 394.096436,239.960068 394.113251,252.953873 
	C394.114868,254.203613 393.701416,255.631149 394.902374,256.661194 
	C405.532806,250.165466 407.789368,246.367950 407.881195,234.877823 
	C407.906494,231.712769 408.105835,228.538345 407.928070,225.384979 
	C407.666504,220.745117 409.349609,217.165451 412.481232,213.728546 
	C423.132629,202.038879 433.790833,190.335953 443.943420,178.216736 
	C453.900970,166.330353 453.005707,149.270355 442.500305,137.431793 
	C432.953430,126.673347 415.140503,124.117622 402.701355,131.772751 
	C400.285583,133.259445 397.139496,134.859558 396.751099,137.557632 
	C395.675873,145.026245 395.800232,152.599838 396.695923,160.410339 
	C400.222778,159.208923 401.549622,156.516144 403.536804,154.599564 
	C405.570099,152.638565 407.301544,150.354401 409.398499,148.471634 
	C414.908081,143.524872 422.384644,143.425613 428.101898,148.050507 
	C433.579346,152.481445 434.953033,159.644089 431.332916,166.023972 
	C430.197723,168.024551 428.520935,169.717850 426.616699,172.085587 
M358.897217,168.737671 
	C359.566925,165.840118 361.560608,163.796570 363.449707,161.687057 
	C370.558258,153.749023 377.098907,145.187378 384.934296,138.046600 
	C393.275879,130.444534 396.303131,121.755569 394.778503,110.985909 
	C394.429962,108.523819 395.625305,105.742348 393.488678,103.490219 
	C392.799286,103.829094 392.249817,103.937317 391.949158,104.270218 
	C377.221008,120.577232 361.853973,136.364807 347.978821,153.369522 
	C332.046844,172.894913 343.315399,200.559341 368.087067,204.535904 
	C376.894958,205.949799 385.033447,203.835220 391.978760,198.155685 
	C397.186310,193.897186 393.782013,187.826904 394.738190,182.655930 
	C394.827545,182.172791 394.798615,181.652893 394.740692,181.159912 
	C394.427063,178.490738 395.529938,175.561508 393.375610,172.893036 
	C389.347198,175.498337 386.705048,179.176025 383.590790,182.330017 
	C378.164001,187.826050 372.046082,189.021194 365.499268,185.662735 
	C359.797119,182.737579 357.309021,176.809433 358.897217,168.737671 
z"/>

</svg>
    </CenteredImageContainer>
        <CenteredImageContainer>
        <svg
          version="1.1"
          id="Layer_2"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100%"
          viewBox="0 0 768 528"
          enableBackground="new 0 0 768 528"
        >
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M477.588348,414.624573 
	C487.196136,395.823181 496.636841,377.355530 506.077545,358.887909 
	C506.684357,358.835480 507.291199,358.783051 507.898010,358.730621 
	C518.851013,379.987549 529.803955,401.244476 540.756958,422.501404 
	C541.196777,422.412384 541.636536,422.323395 542.076355,422.234406 
	C542.076355,419.155273 542.077148,416.076111 542.076233,412.996979 
	C542.071594,397.331421 542.044067,381.665802 542.071350,366.000305 
	C542.084167,358.648590 542.131775,358.620819 549.676819,358.603271 
	C558.676147,358.582367 567.704529,359.083435 576.669067,358.529541 
	C596.540222,357.301727 614.037109,379.006989 602.584167,401.000824 
	C598.044617,409.718475 582.134338,417.091034 572.866455,414.553253 
	C571.367126,414.142700 570.530457,413.374969 570.531799,411.801453 
	C570.532349,411.135406 570.578125,410.469452 570.584778,409.803253 
	C570.676208,400.690735 570.675354,400.690735 579.706116,399.967102 
	C586.312378,399.437775 590.888000,393.786926 590.737793,386.343018 
	C590.590149,379.024902 584.812317,372.600647 577.792236,372.276733 
	C572.969360,372.054199 568.119324,372.403809 563.298218,372.163086 
	C559.362793,371.966644 558.049988,373.514923 558.082397,377.391205 
	C558.231323,395.222168 558.162292,413.054871 558.187988,430.886963 
	C558.198792,438.401703 558.217224,438.399719 550.529419,438.369568 
	C547.198303,438.356506 543.795654,437.911682 540.550903,438.437317 
	C533.678467,439.550537 530.463074,436.211945 527.803284,430.341217 
	C522.581238,418.815247 516.681274,407.595245 511.001770,396.278778 
	C510.050842,394.384125 509.484497,392.189728 506.961426,390.497101 
	C502.760498,396.851257 499.720886,403.605438 496.995270,411.609100 
	C507.309845,410.694611 507.229706,419.048798 511.131714,425.176056 
	C504.692535,426.518860 499.062531,426.122131 493.560547,425.625793 
	C489.651459,425.273163 487.369904,426.487549 486.408844,430.045776 
	C484.596405,436.756012 480.566742,439.470428 473.556610,438.375916 
	C469.979095,437.817352 466.180115,437.876160 462.586700,438.391327 
	C455.605865,439.392151 451.121277,436.689911 447.334503,430.854431 
	C441.263977,421.499664 434.477692,412.610046 428.024658,403.502075 
	C427.288361,402.462891 426.151550,401.526642 426.655243,399.631165 
	C430.294739,398.013184 434.359192,398.672180 438.183014,398.961945 
	C449.785248,399.841278 455.660645,389.557953 450.815216,378.256226 
	C449.097351,374.249420 445.529022,372.346100 441.170471,372.268311 
	C435.839050,372.173157 430.495819,372.410217 425.175262,372.147552 
	C421.150726,371.948883 419.996368,373.648651 420.024078,377.438354 
	C420.155640,395.436188 420.098969,413.435425 420.109436,431.434143 
	C420.113403,438.263855 420.050781,438.325562 413.094940,438.348816 
	C411.928375,438.352722 410.747223,438.241394 409.597809,438.384308 
	C405.083893,438.945435 403.879730,436.749939 403.922333,432.554749 
	C404.106964,414.390808 404.064056,396.224548 404.102325,378.059113 
	C404.107391,375.657654 404.103088,373.256195 404.103088,370.854706 
	C403.620911,370.780884 403.138733,370.707062 402.656555,370.633209 
	C398.782715,381.151825 394.908875,391.670410 390.950439,402.418701 
	C387.728027,401.321350 387.848724,398.418915 386.571594,396.502960 
	C381.106445,388.303986 382.164856,380.442627 385.855255,371.613068 
	C392.465515,355.797455 387.989716,358.990479 404.553497,358.675568 
	C416.215088,358.453888 427.884918,358.577789 439.550201,358.664703 
	C453.927063,358.771790 463.080811,364.904205 466.913757,376.821777 
	C470.823212,388.977325 466.908203,401.000488 456.131317,409.073029 
	C453.451324,411.080536 452.634308,412.679291 454.997650,415.482056 
	C459.363068,420.659180 462.535431,426.794647 467.907196,432.096863 
	C472.082092,426.580902 474.280884,420.489899 477.588348,414.624573 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M177.938126,362.742554 
	C194.213989,355.581329 209.350082,357.195343 223.808487,366.707031 
	C225.881165,368.070557 226.601715,369.464294 224.933060,371.558075 
	C224.829315,371.688263 224.730469,371.823303 224.640045,371.963043 
	C219.129074,380.479431 219.177628,380.370300 209.918106,376.264008 
	C202.712662,373.068634 195.303864,371.615784 187.712906,375.203979 
	C185.033035,376.470703 182.696411,378.136963 182.555847,381.374725 
	C182.411102,384.708832 184.755966,386.401215 187.447174,387.644836 
	C195.555161,391.391663 204.601151,391.611511 212.972961,394.410583 
	C219.302811,396.526978 224.986420,399.339783 228.066849,405.739502 
	C235.321915,420.812195 227.398193,436.542114 210.622437,440.041901 
	C195.439896,443.209290 181.546707,440.021179 168.916245,431.081360 
	C167.412582,430.017090 166.197906,428.728058 167.226242,426.949860 
	C168.972229,423.930603 170.936310,421.019745 173.015076,418.216339 
	C174.625336,416.044708 176.272354,417.656067 177.772018,418.608459 
	C185.217758,423.336975 193.004486,426.896057 202.158768,425.923859 
	C203.317612,425.800812 204.505203,425.846344 205.640244,425.618408 
	C210.831772,424.575958 214.883987,420.553009 214.753479,416.396942 
	C214.584610,411.019440 210.159271,409.714142 206.261917,408.611053 
	C200.351303,406.938080 194.227249,406.033875 188.235184,404.627014 
	C185.658676,404.022095 183.144379,403.081299 180.673401,402.110840 
	C172.952957,399.078735 167.827682,393.671417 166.828781,385.277008 
	C165.687149,375.683136 168.916840,367.903381 177.938126,362.742554 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M240.787033,421.995911 
	C240.804871,403.013611 240.807831,384.530212 240.848541,366.046906 
	C240.864670,358.726196 240.924515,359.012482 248.157791,358.465546 
	C258.155457,357.709625 268.132782,358.147919 278.084534,358.662140 
	C293.392395,359.453156 304.271118,370.856110 305.023529,386.182831 
	C305.723541,400.442291 295.837128,411.885986 280.707947,414.325531 
	C280.215027,414.405029 279.717834,414.457947 279.223267,414.527679 
	C269.301544,415.925934 266.309479,412.463348 269.060669,402.806946 
	C270.119263,399.091492 273.190521,400.121979 275.674561,400.049835 
	C283.555450,399.820831 286.713226,397.540924 288.739136,390.353668 
	C291.185791,381.673828 284.894531,372.774231 275.870178,372.364929 
	C271.383331,372.161407 266.865997,372.541718 262.388062,372.256134 
	C258.147552,371.985748 256.963593,373.710480 257.005432,377.778961 
	C257.185181,395.261383 257.135101,412.746796 257.056702,430.230621 
	C257.044617,432.921417 258.240234,436.802124 255.043427,437.838745 
	C251.018585,439.143829 246.468536,439.025848 242.245407,437.920258 
	C240.613907,437.493164 240.800003,435.476257 240.790619,433.984406 
	C240.766556,430.154694 240.785507,426.324707 240.787033,421.995911 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M339.643738,388.722198 
	C344.359467,378.673279 348.920135,368.968933 353.790527,358.605530 
	C358.340485,363.444275 359.619690,368.903503 361.942139,373.721375 
	C368.949188,388.257233 375.603882,402.962616 382.485535,417.559631 
	C383.550262,419.818024 383.923401,421.940247 382.976257,424.263000 
	C381.123596,428.806519 379.284119,433.355438 377.439148,437.902100 
	C376.885406,437.987579 376.331696,438.073029 375.777954,438.158508 
	C368.941864,423.418854 362.105804,408.679199 355.269714,393.939575 
	C354.797241,393.901093 354.324799,393.862610 353.852325,393.824127 
	C346.886963,408.531036 339.921570,423.237915 332.956207,437.944824 
	C332.341614,437.953064 331.727051,437.961304 331.112457,437.969574 
	C321.570618,411.962219 312.028748,385.954865 302.346893,359.565948 
	C307.487274,358.018127 311.944214,358.619049 316.338501,358.837494 
	C318.855988,358.962616 319.171844,361.375946 319.834778,363.193420 
	C323.538025,373.346222 327.175446,383.523071 330.859528,393.682953 
	C331.588165,395.692444 332.422272,397.663696 333.419800,400.189606 
	C336.725677,396.721191 337.701691,392.663116 339.643738,388.722198 
z"
          />
        </svg>
        </CenteredImageContainer>
        <CenteredImageContainer>
        <svg
          version="1.1"
          id="Layer_3"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100%"
          viewBox="0 0 768 528"
          enableBackground="new 0 0 768 528"
        >
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M295.589600,459.549133 
	C298.589111,458.662445 298.598755,461.836029 300.364105,462.608429 
	C301.961731,460.043732 301.615479,457.070312 302.542389,454.439148 
	C303.014313,453.099640 303.556274,451.735657 305.212860,452.141205 
	C306.929779,452.561523 306.683655,454.101105 306.430634,455.441772 
	C305.661713,459.516205 304.895416,463.591431 304.083740,467.657440 
	C303.764496,469.256531 303.723022,471.272522 301.758545,471.687653 
	C299.607239,472.142303 299.194580,470.102264 298.411926,468.682220 
	C298.029388,467.988190 297.448090,467.403717 297.024261,466.856781 
	C296.462860,466.943024 296.062164,466.883881 295.849243,467.056396 
	C294.166962,468.419708 294.484253,472.337311 291.394043,471.542053 
	C288.775330,470.868103 289.437500,467.628143 288.857269,465.491791 
	C287.990204,462.299469 287.353577,459.031860 286.844421,455.761139 
	C286.631744,454.395111 286.352692,452.401581 288.499481,452.256775 
	C290.448578,452.125305 290.707825,453.975739 290.972382,455.429535 
	C291.408020,457.823456 291.316406,460.339233 292.703308,462.865814 
	C293.698547,461.647217 294.497589,460.668884 295.589600,459.549133 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M548.717407,466.302490 
	C547.179749,464.638489 546.657349,462.515991 544.104065,461.392059 
	C544.104065,464.162689 544.145142,466.588776 544.086548,469.012451 
	C544.057678,470.209229 543.760010,471.533051 542.347290,471.659210 
	C540.632202,471.812378 540.041870,470.413116 540.013245,469.025330 
	C539.917297,464.367371 539.928528,459.706360 539.967712,455.047089 
	C539.977661,453.866974 540.273499,452.618378 541.603699,452.237061 
	C543.150696,451.793579 544.118408,452.840973 544.877563,453.968231 
	C546.812866,456.842041 548.684265,459.758911 551.689697,462.492981 
	C551.689697,460.121765 551.648987,457.749634 551.704407,455.379730 
	C551.739563,453.878479 551.757019,452.068207 553.854004,452.135712 
	C555.875610,452.200806 555.949585,454.035858 555.955139,455.505005 
	C555.972046,459.985382 555.883911,464.466736 555.785522,468.946533 
	C555.763306,469.957245 555.489014,471.029419 554.465149,471.462555 
	C553.178467,472.006897 552.217041,471.235413 551.483032,470.266815 
	C550.580505,469.075836 549.764832,467.819000 548.717407,466.302490 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M334.752258,467.633179 
	C334.750305,463.691315 334.932587,460.193359 334.716644,456.720154 
	C334.364410,451.055023 338.246002,451.693237 341.713593,451.880005 
	C346.454712,452.135284 350.115326,455.386353 348.413696,458.391937 
	C345.799713,463.008972 345.395721,466.956451 349.740051,471.589783 
	C342.599152,472.158600 343.775482,465.163086 339.782867,464.161041 
	C337.950378,465.112305 338.708984,466.704956 338.737427,468.116211 
	C338.767670,469.616852 338.911194,471.571411 337.023193,471.716034 
	C334.639465,471.898590 334.922974,469.692047 334.752258,467.633179 
M339.600311,459.447327 
	C341.658447,460.085602 343.679840,460.282715 344.420776,457.699432 
	C344.734436,456.605774 343.644287,455.804871 342.588623,455.846741 
	C340.664215,455.923096 338.263123,455.744415 339.600311,459.447327 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M584.927612,456.616516 
	C586.885132,451.342041 590.884216,451.149719 594.937805,452.119019 
	C598.582703,452.990570 600.491821,455.478455 599.675293,459.573334 
	C599.464722,460.629486 600.045532,461.825928 600.163940,462.968170 
	C600.774109,468.855194 598.454590,471.946808 593.231262,472.261993 
	C587.537415,472.605530 584.790161,469.975159 584.704163,464.003326 
	C584.670776,461.684875 584.787842,459.364258 584.927612,456.616516 
M588.319458,460.792236 
	C588.344055,462.288086 588.283752,463.791443 588.414612,465.277924 
	C588.581604,467.175537 589.775085,468.281555 591.594177,468.601715 
	C592.933716,468.837494 594.348999,468.793579 595.168457,467.466797 
	C596.517090,465.283234 594.183533,464.722534 593.333069,463.493073 
	C593.081116,463.128845 593.093628,462.581635 593.054321,462.414001 
	C593.457153,460.085052 596.398804,461.428711 596.747559,459.211548 
	C595.783142,457.359863 595.036072,455.196533 592.157532,455.647644 
	C589.682861,456.035400 588.220642,457.304779 588.319458,460.792236 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M207.746292,453.327789 
	C211.107559,452.710968 214.175613,451.607849 217.140381,453.726013 
	C215.877350,458.266876 210.688126,454.478821 208.981415,457.744873 
	C210.043137,459.490448 211.777481,458.773926 213.108246,459.205963 
	C216.402054,460.275330 218.114761,462.505707 217.934769,465.903259 
	C217.769958,469.014374 216.179886,471.341400 212.967651,471.964630 
	C209.660446,472.606384 206.605515,472.159302 204.854889,468.729248 
	C202.641235,464.392029 203.847534,457.243683 207.746292,453.327789 
M209.138672,463.557953 
	C207.679550,465.488647 207.712189,467.309418 210.079727,468.271637 
	C211.651901,468.910583 213.122879,467.896820 213.419144,466.290802 
	C213.943909,463.446014 212.023575,463.063477 209.138672,463.557953 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M254.490784,472.069855 
	C250.579712,472.574738 247.140579,472.242615 245.738571,468.503693 
	C244.071243,464.057159 243.874023,459.357513 246.513763,455.142334 
	C248.209061,452.435211 251.132523,452.079987 254.056442,452.731720 
	C257.793335,453.564636 259.600250,456.299103 259.925507,459.854492 
	C260.367615,464.688171 260.774292,469.687042 254.490784,472.069855 
M248.963913,464.159027 
	C249.089508,466.492157 249.669815,468.678040 252.546829,468.445404 
	C255.031509,468.244507 255.610916,466.101288 255.557449,464.028625 
	C255.484024,461.181915 256.360474,457.057831 252.471024,456.697083 
	C247.872452,456.270538 249.402054,460.668396 248.963913,464.159027 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M378.901367,464.002808 
	C380.211548,460.546051 381.369019,457.445740 382.561218,454.358856 
	C383.351349,452.313110 385.424469,450.805450 386.445068,453.045349 
	C389.120453,458.917023 391.722717,464.943420 392.902130,471.331909 
	C390.047791,472.510651 389.576508,470.661407 388.588959,469.395691 
	C386.469299,466.678986 383.944183,466.340485 381.550018,468.970917 
	C380.512573,470.110779 380.206482,472.605530 378.116455,471.554596 
	C375.828857,470.404388 377.651733,468.345123 377.930908,466.698334 
	C378.066864,465.896545 378.477264,465.141266 378.901367,464.002808 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M473.875427,463.151733 
	C469.824524,463.535553 467.597778,465.057281 468.037598,469.198669 
	C468.158630,470.338501 467.732483,471.648560 466.211090,471.668304 
	C464.728943,471.687561 464.219391,470.427612 464.198914,469.275421 
	C464.110779,464.310242 464.085632,459.340576 464.210175,454.376831 
	C464.254456,452.611206 465.605743,451.852417 467.310547,451.835876 
	C470.937744,451.800720 474.742523,451.244354 477.211639,454.908295 
	C478.455688,456.754395 478.364380,458.657623 477.250153,460.526611 
	C476.549438,461.701965 475.516418,462.516388 473.875427,463.151733 
M472.562195,459.308044 
	C474.004669,457.815857 473.788788,456.645996 471.745392,456.180908 
	C470.455811,455.887390 468.750427,455.973114 468.563629,457.585693 
	C468.264252,460.170197 470.266876,459.787689 472.562195,459.308044 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M423.929565,451.881165 
	C426.619751,451.847992 428.965240,451.461182 431.192505,452.333710 
	C433.726501,453.326385 435.309570,455.078644 435.213684,457.956177 
	C435.118683,460.807404 433.660034,462.841553 430.853455,463.155762 
	C426.930756,463.594879 424.721497,465.080872 425.218811,469.404480 
	C425.350952,470.553528 424.798523,471.771637 423.253479,471.701782 
	C422.066162,471.648071 421.497803,470.686981 421.386536,469.699127 
	C420.754913,464.092163 421.425568,458.512970 421.982971,452.943115 
	C422.021759,452.555450 423.001617,452.261963 423.929565,451.881165 
M426.838287,459.677979 
	C428.730164,459.904480 430.463928,459.836517 431.013031,457.431549 
	C429.758789,455.480438 427.965179,455.651978 426.331726,456.531219 
	C425.127441,457.179474 425.227295,458.397064 426.838287,459.677979 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M167.732452,460.478882 
	C169.535507,459.806366 171.516037,460.321136 172.304077,458.203186 
	C171.570663,455.446838 169.182938,456.529572 167.415756,456.225494 
	C165.978439,455.978210 164.006149,456.155670 164.206604,454.063629 
	C164.399399,452.051575 166.338409,452.426636 167.786224,452.416595 
	C175.212830,452.365051 176.851898,454.090820 176.358231,461.345154 
	C176.302734,462.160583 176.302826,463.022888 176.504135,463.805023 
	C177.274994,466.800079 176.890213,469.440247 174.189072,471.282898 
	C171.748901,472.947449 166.964310,472.505463 164.173843,470.482361 
	C165.146713,467.905792 167.457962,468.866028 169.269073,468.586761 
	C170.812256,468.348724 172.404434,468.061890 172.457123,466.152313 
	C172.507706,464.319122 170.894775,464.421295 169.604431,464.128815 
	C167.787933,463.717072 164.935425,463.785950 167.732452,460.478882 
z"
          />
          <path
            fill="#0651d1"
            opacity="1.000000"
            stroke="#0651d1"
            d="
M506.759155,468.551941 
	C506.744873,463.958313 506.716858,459.814789 506.770081,455.672241 
	C506.788818,454.212524 506.667969,452.292511 508.662964,452.190247 
	C510.994995,452.070709 511.022522,454.134277 511.035400,455.773529 
	C511.069305,460.080688 511.031219,464.389984 510.916931,468.695679 
	C510.882263,470.001465 510.665253,471.565887 508.969727,471.704926 
	C507.191864,471.850769 506.962646,470.355865 506.759155,468.551941 
z"
          />
        </svg>
        </CenteredImageContainer>
    </>
  );
};

export default Logos;
